<template>
  <div class="content">
    <TitleInfo title1="设备管理" title2="设备参数"></TitleInfo>
    <div class="main">
      <div class="title flexCB">
        <div class="key align-center">
          <div>设备类型：</div>
          <el-select v-model="queryForm.deviceTypeID" placeholder="Select">
            <el-option v-for="item in typeInfo" :key="item.ID" :label="item.Name" :value="item.ID" />
          </el-select>
          <div style="margin-left: 10px">参数名称：</div>
          <el-input v-model="queryForm.name" placeholder="请输入参数名称" class="keyInput" />
          <div>位号名称：</div>
          <el-input v-model="queryForm.dcsName" placeholder="请输入位号名称" class="keyInput" />
          <el-button :icon="Search" color="#11488B" :round="false" @click="initGetList">搜索</el-button>
          <el-button :icon="Refresh" color="#67C23A" :round="false" @click="resetInfo">重置</el-button>
          <el-button v-if="user.CanAdd" :icon="Plus" type="warning" :round="false"
            @click="handleDialogValue()">新增</el-button>
        </div>
      </div>
      <div class="tableInfo">
        <el-table :data="tableData" border class="tableInfo" style="width: 100%; height: 100%">
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column prop="DeviceTypeName" label="设备类型"></el-table-column>
          <el-table-column prop="Name" label="参数名称"></el-table-column>
          <el-table-column prop="DCSName" label="位号名称"></el-table-column>
          <el-table-column prop="Unit" label="参数单位"></el-table-column>
          <el-table-column prop="Sort" label="序号"></el-table-column>
          <el-table-column prop="Type" label="数据类型"></el-table-column>
          <el-table-column prop="ToHistoryLabel" label="存为历史"></el-table-column>
          <el-table-column prop="RatioX" label="乘系数"></el-table-column>
          <el-table-column prop="RatioAdd" label="加系数"></el-table-column>
          <el-table-column prop="Round" label="保留位数"></el-table-column>
          <el-table-column prop="ReadType" label="读写权限"></el-table-column>
          <el-table-column prop="BeAlarmLabel" label="报警判定"></el-table-column>
          <el-table-column prop="BeFaultLabel" label="故障判定"></el-table-column>
          <el-table-column prop="BePowerLabel" label="耗电参数"></el-table-column>
          <el-table-column prop="BeEnergyLabel" label="能耗参数"></el-table-column>
          <el-table-column prop="Remarks" label="备注"></el-table-column>
          <el-table-column prop="action" label="操作" width="200" v-if="user.CanDelete || user.CanEdit">
            <template #default="{ row }">
              <el-button v-if="user.CanEdit" size="small" type="warning" :icon="Edit"
                @click="handleDialogValue(row)">编辑</el-button>
              <el-button v-if="user.CanDelete" size="small" type="danger" :icon="Delete" @click="del(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom align-center">
        <el-pagination v-model:current-page="queryForm.pageIndex" v-model:page-size="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]" :background="true" layout="total, sizes, prev, pager, next, jumper"
          :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          popper-class="dropdownPopper" />
      </div>
    </div>
    <Dialog v-model="dialogVisible" :dialogTitle="dialogTitle" :dialogRowValue="rowInfo" v-if="dialogVisible"
      @initList="initGetList"></Dialog>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import {
  Search,
  Refresh,
  Plus,
  Edit,
  Delete,
  Download,
  Upload
} from '@element-plus/icons-vue'
import { isNull } from '@/utitls/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import TitleInfo from '@/components/TitleInfo'
import { GetDeviceTypeFillList } from '@/api/DeviceType'
import { GetDeviceParamePageList, DeleteDeviceParame } from '@/api/DeviceParame'
import Dialog from './components/dialog.vue'
import { useStore } from 'vuex'
const queryForm = ref({
  deviceTypeID: '',
  name: '',
  dcsName: '',
  pageIndex: 1,
  pageSize: 10
})
const typeInfo = ref([
  {
    ID: '',
    Name: '全部'
  }
])

const total = ref(0)
const tableData = reactive([])
const dialogVisible = ref(false)
const dialogTitle = ref('')
const rowInfo = ref({})
const user = ref({})
onMounted(() => {
  user.value = useStore().state.login.user
  GetDeviceTypeFillList()
    .then((res) => {
      if (res) {
        res.Data.forEach((item) => {
          typeInfo.value.push({
            ID: item.ID,
            Name: item.Name
          })
        })
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
    })
  initGetList()
})
const initGetList = async () => {
  GetDeviceParamePageList(queryForm.value)
    .then((res) => {
      if (res) {
        total.value = res.Total
        tableData.length = 0
        res.Data.forEach((element) => {
          tableData.push(element)
        })
      } else {
        total.value = 0
        tableData.length = 0
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
      console.log(err)
      total.value = 0
      tableData.length = 0
    })
}
const handleSizeChange = (pageSize) => {
  queryForm.value.pageIndex = 1
  initGetList()
}
const handleCurrentChange = (pageNum) => {
  initGetList()
}
const handleDialogValue = (row) => {
  if (isNull(row)) {
    dialogTitle.value = '添加设备参数'
    rowInfo.value = {}
  } else {
    dialogTitle.value = '修改设备参数信息'
    rowInfo.value = JSON.parse(JSON.stringify(row))
  }
  dialogVisible.value = true
}
const del = (row) => {
  ElMessageBox.confirm('是否确认删除当前设备参数?', '提醒', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      DeleteDeviceParame({ id: row.ID })
        .then((res) => {
          if (res) {
            ElMessage({
              showClose: true,
              type: 'success',
              message: '删除成功!'
            })
            initGetList()
          }
        })
        .catch((err) => {
          ElMessage({
            showClose: true,
            type: 'error',
            message: err,
            duration: 2000
          })
        })
    })
    .catch(() => { })
}
const resetInfo = () => {
  queryForm.value = {
    deviceTypeID: '',
    name: '',
    dcsName: '',
    pageIndex: 1,
    pageSize: 10
  }
  initGetList()
}
</script>
<style lang="scss" scoped src="@/styles/elementReset.scss"></style>
<style lang="scss" scoped src="./assets/index.scss"></style>
