<template>
  <el-dialog :model-value="dialogVisible" :title="dialogTitle" width="500px" @opened="handleOpen" @close="handleClose"
    draggable>
    <el-form :model="form" label-width="85px" ref="formRef" :rules="rules">
      <el-form-item label="参数名称" prop="Name">
        <el-input v-model="form.Name" />
      </el-form-item>
      <el-form-item label="设备类型" prop="DeviceTypeID">
        <el-select v-model="form.DeviceTypeID" placeholder="Select" style="width: 100%">
          <el-option v-for="item in typeInfo" :key="item.ID" :label="item.Name" :value="item.ID" />
        </el-select>
      </el-form-item>
      <el-form-item label="位号名称" prop="DCSName">
        <el-input v-model="form.DCSName" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="参数单位" prop="Unit">
        <el-input v-model="form.Unit" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="序号">
        <el-input-number v-model="form.Sort" :min="0" />
      </el-form-item>
      <el-form-item label="数据类型">
        <el-select v-model="form.Type" placeholder="Select" style="width: 100%">
          <el-option v-for="item in parameTypes" :key="item.Key" :label="item.Name" :value="item.Key" />
        </el-select>
      </el-form-item>
      <el-form-item label="转为历史">
        <el-radio-group v-model="form.ToHistory">
          <el-radio :label="true" size="large">是</el-radio>
          <el-radio :label="false" size="large">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="乘系数">
        <el-input-number v-model="form.RatioX" :min="0" :precision="3" />
      </el-form-item>
      <el-form-item label="加系数">
        <el-input-number v-model="form.RatioAdd" :min="0" :precision="3" />
      </el-form-item>
      <el-form-item label="保留位数">
        <el-input-number v-model="form.Round" :min="0" />
      </el-form-item>
      <el-form-item label="转为历史">
        <el-radio-group v-model="form.ToHistory">
          <el-radio :label="true" size="large">是</el-radio>
          <el-radio :label="false" size="large">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="读写权限">
        <el-radio-group v-model="form.ReadType">
          <el-radio label="只读" size="large">只读</el-radio>
          <el-radio label="读写" size="large">读写</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="报警判定">
        <el-radio-group v-model="form.BeAlarm">
          <el-radio :label="true" size="large">是</el-radio>
          <el-radio :label="false" size="large">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="故障判定">
        <el-radio-group v-model="form.BeFault">
          <el-radio :label="true" size="large">是</el-radio>
          <el-radio :label="false" size="large">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="耗电参数">
        <el-radio-group v-model="form.BePower">
          <el-radio :label="true" size="large">是</el-radio>
          <el-radio :label="false" size="large">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="能耗参数">
        <el-radio-group v-model="form.BeEnergy">
          <el-radio :label="true" size="large">是</el-radio>
          <el-radio :label="false" size="large">否</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { AddDeviceParame, EditDeviceParame } from '@/api/DeviceParame'
import { GetDeviceTypeFillList } from '@/api/DeviceType'
import { ElMessage } from 'element-plus'
import { isNull } from '@/utitls/validate'
import { Search, Refresh } from '@element-plus/icons-vue'
const props = defineProps({
  dialogTitle: {
    type: String,
    default: '',
    required: true
  },
  dialogRowValue: {
    type: Object,
    default: () => { }
  }
})
const formRef = ref(null)
var form = ref({
  ToHistory: false,
  RatioX: 1,
  RatioAdd: 0,
  Round: 0,
  ReadType: '只读',
  BeAlarm: false,
  BeFault: false,
  BePower: false,
  BeEnergy: false
})
const typeInfo = ref([])
const dialogVisible = ref(false)
const rules = reactive({
  Name: [{ required: true, message: '参数名称不能为空', trigger: 'blur' }],
  DeviceModelID: [
    { required: true, message: '设备类型不能为空', trigger: 'blur' }
  ]
})
const parameTypes = ref([
  {
    Name: '无符号整型16位2字节-UInt16/ushort',
    Key: 'UInt16'
  },
  {
    Name: '无符号整型32位4字节-UInt32/uint',
    Key: 'UInt32'
  },
  {
    Name: '无符号整型64位8字节-UInt64/ulong',
    Key: 'UInt64'
  },
  {
    Name: '有符号整型16位2字节-Int16/short',
    Key: 'Int16'
  },
  {
    Name: '有符号整型32位4字节-Int32/int',
    Key: 'Int32'
  },
  {
    Name: '有符号整型64位8字节-Int64/long',
    Key: 'Int64'
  },
  {
    Name: '单精度浮点32位4字节-single/float',
    Key: 'Float'
  },
  {
    Name: '双精度浮点64位8字节-Double/double',
    Key: 'Double'
  },
  {
    Name: 'Boolean16位2字节-Boolean/bool',
    Key: 'Boolean'
  },
  {
    Name: 'Byte8位1字节-Byte/byte',
    Key: 'Byte'
  },
  {
    Name: '字符串',
    Key: 'String'
  }
])
const emits = defineEmits(['update:modelValue'])
const handleOpen = () => {
  GetDeviceTypeFillList().then((res) => {
    if (res) {
      res.Data.forEach((item) => {
        typeInfo.value.push({
          ID: item.ID,
          Name: item.Name
        })
      })
    }
  })
  if (props.dialogTitle === '修改设备参数信息') {
    form.value = props.dialogRowValue
  }
}
const handleClose = () => {
  emits('update:modelValue', false)
}
const handleConfirm = () => {
  formRef.value.validate(async (valid, fields) => {
    if (valid) {
      if (props.dialogTitle == '添加设备参数') {
        AddDeviceParame(form.value)
          .then((res) => {
            ElMessage({
              showClose: true,
              message: '操作成功',
              type: 'success'
            })
            emits('initList')
            handleClose()
          })
          .catch((err) => {
            ElMessage({
              showClose: true,
              message: `操作失败:${err}`,
              type: 'error'
            })
          })
      } else
        EditDeviceParame(form.value)
          .then((res) => {
            ElMessage({
              showClose: true,
              message: '操作成功',
              type: 'success'
            })
            emits('initList')
            handleClose()
          })
          .catch((err) => {
            ElMessage({
              showClose: true,
              message: `操作失败:${err}`,
              type: 'error'
            })
          })
    } else {
      console.log('error submit!', fields)
    }
  })
}
</script>
